import { SiKubernetes } from "react-icons/si";
import { FaCloud } from "react-icons/fa";
import { GrDeploy } from "react-icons/gr";
import { FcDeployment } from "react-icons/fc";



const Industries = [
    {
        name: "Finance",
        desc: "Leverage AI for fraud detection, risk assessment, and automated trading to enhance financial security and optimize investment strategies.",
        icon: <FaCloud />,
        button: "Maximize AI in Finance",
        "img":'finance.png',
    },
    {
        name: "Insurance",
        desc: "Implement AI-driven claims processing, predictive underwriting, and customer support automation to improve efficiency and reduce costs.",
        icon: <FcDeployment />,
        button: "Transform Insurance AI",
        "img":'insurance.png',
    },
    {
        name: "Retail",
        desc: "Use AI to personalize customer experiences, optimize inventory management, and enhance demand forecasting for a smarter retail ecosystem.",
        icon: <SiKubernetes color='#326DE6' />,
        button: "Revolutionize Retail",
        "img":'retail.png',
    },
    {
        name: "Manufacturing",
        desc: "Enhance production efficiency with AI-driven predictive maintenance, automated quality control, and intelligent supply chain management.",
        icon: <GrDeploy color='#00E887' />,
        button: "Optimize Manufacturing AI",
        "img":'manufacturing.png',
    }
];



export default Industries