import { SiKubernetes } from "react-icons/si";
import { FaCloud } from "react-icons/fa";
import { GrDeploy } from "react-icons/gr";
import { FcDeployment } from "react-icons/fc";
import { FaDatabase } from "react-icons/fa6";
import { FaChartLine } from "react-icons/fa";
import { FaChartBar } from "react-icons/fa";
import { FaCogs } from "react-icons/fa";
import { FaCode } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { FaStream } from "react-icons/fa";


const menus = [
    {
        name:'Services',
        show: true,
        dropdown: true,
        options:[{
            desc: 'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
            icon: <FaCloud />,
            button: 'Cloud Consulting',
            link: '/cloudconsulting'
        },
        {
            desc: 'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
            icon:<GrDeploy color="#00E887"/>,
            button:'Cloud native development',
            link: '/cloudnativedevelopment'
        },
        {
            desc: 'Elevate your software development with our DevOps expertise. Unlock rapid deployment, enhanced collaboration, and unmatched scalability to deliver exceptional products efficiently.',
            icon: <FcDeployment />,
            button: 'DevOps/DevSecOps',
            link: '/devops'
        },
        {
            desc: 'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
            icon: <SiKubernetes  color="#326DE6"/>,
            button: 'Kubernetes consulting',
            link: '/kubernetes'
        },
        {
            desc: 'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
            icon:<GrDeploy color="#00E887"/>,
            button:'Observability',
            link: '/observability'
        },
        {
            desc: 'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
            icon:<GrDeploy color="#00E887"/>,
            button:'Platform Engineering',
            link: '/platformengineering'
        },
        {
            desc: 'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
            icon:<GrDeploy color="#00E887"/>,
            button:'Site Reliability Engineering',
            link: '/sitereliabilityengineering'
        },
        {
            desc: 'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
            icon:<GrDeploy color="#00E887"/>,
            button:'Backstage Consulting',
            link: '/backstageconsulting'
        },
        
    
    ],
    },
    {
        name:'Open Source Support',
        show: true,
        dropdown: true,
        options:[{
            desc: 'Unlock the power of PostgreSQL with our expert guidance - from high-performance tuning to scalable architectures for seamless database operations.',
            icon: <FaDatabase />,
            button: 'PostgreSQL',
            link: '/postgresql'
        },
        {
            desc: 'Streamline your Kubernetes deployments with FluxCD - empowering GitOps-driven automation for continuous delivery and operational excellence.',
            icon: <FaCloud />,
            button: 'FluxCD',
            link: '/fluxcd'
        },
        {
            desc: 'Enhance your CI/CD workflows with ArgoCD - delivering declarative GitOps-based deployments and real-time Kubernetes application management.',
            icon: <FaCloud />,
            button: 'ArgoCD',
            link: '/argocd'
        },
        {
            desc: 'Gain deep observability into your systems with Grafana - enabling real-time dashboards, analytics, and actionable insights for better decision-making.',
            icon: <FaChartBar />,
            button: 'Grafana',
            link: '/grafana'
        },
        {
            desc: 'Monitor and scale your infrastructure with Prometheus - a powerful open-source monitoring and alerting toolkit designed for reliability and performance.',
            icon: <FaChartLine />,
            button: 'Prometheus',
            link: '/prometheus'
        },
        {
            desc: 'Build event-driven, distributed applications with Dapr - simplifying microservices development with state management, service invocation, and observability.',
            icon: <FaCogs />,
            button: 'Dapr',
            link: '/dapr'
        },
        {
            desc: 'Transform your developer experience with Backstage - a powerful platform for managing software catalogs, documentation, and internal tools at scale.',
            icon: <FaCode />,
            button: 'Backstage',
            link: '/backstage'
        },
        {
            desc: 'Improve observability with OpenTelemetry - a flexible framework for collecting and exporting telemetry data across distributed systems and applications.',
            icon: <FaEye />,
            button: 'OpenTelemetry',
            link: '/opentelemetry'
        },
        {
            desc: 'Harness the power of Apache Kafka for real-time data streaming - enabling scalable event-driven architectures and seamless data pipelines.',
            icon: <FaStream />,
            button: 'Apache Kafka',
            link: '/kafka'
        }
    ],
    },
    {
        name:'Products',
        show: false,
        dropdown: false,
        options:[{
            desc: 'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
            icon: <FaCloud />,
            button: 'Generative AI',
            link: '/solutions'
        },
        {
            desc: 'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
            icon: <FaCloud />,
            button: 'Explore Solutions',
            link: '/solutions'
        },
        {
            desc: 'Elevate your business with our cloud consulting mastery - guiding you from seamless migrations to advanced optimizations for unparalleled efficiency and growth.',
            icon: <FaCloud />,
            button: 'Explore Solutions',
            link: '/solutions'
        }
    ],
    },
    {
        name:'Resources',
        show: true,
        dropdown: true,
        options:[{
            desc: 'Blog',
            icon: <FaCloud />,
            button: 'Blog',
            link: '/blogs'
        },
        {
            desc: 'Case Studies',
            icon: <FaCloud />,
            button: 'Case Studies',
            link: '/casestudy'
        },
        {
            desc: 'Glossary',
            icon: <FaCloud />,
            button: 'Glossary',
            link: '/glossary'
        }
    ],
    },
    {
        name:'Company',
        show: true,
        dropdown: true,
        options:[{
            desc: 'About Us',
            icon: <FaCloud />,
            button: 'About Us',
            link: '/about'
        },
        {
            desc: 'Careers',
            icon: <FaCloud />,
            button: 'Careers',
            link: '/careers'
        },
        
    ],
    },
    
]


export default menus