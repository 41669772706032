import { GrDeploy } from "react-icons/gr";
import { FaSearch } from "react-icons/fa";
import { PiBlueprintDuotone } from "react-icons/pi";
import { GrOptimize } from "react-icons/gr";

const strategies = [
    {
        name: "Analyze",
        desc: "Leverage AI-driven data analysis to uncover patterns, identify risks, and generate actionable insights for informed decision-making.",
        icon: <FaSearch />,
        button: "Unlock AI Insights"
    },
    {
        name: "Architect",
        desc: "Design and build AI-powered frameworks, ensuring scalability, security, and efficiency tailored to industry-specific needs.",
        icon: <PiBlueprintDuotone />,
        button: "Build Scalable AI"
    },
    {
        name: "Deploy",
        desc: "Seamlessly integrate AI models into your operations, from fraud detection in finance to predictive maintenance in manufacturing.",
        icon: <GrDeploy />,
        button: "Deploy AI at Scale"
    },
    {
        name: "Optimize",
        desc: "Continuously refine AI systems for peak performance, enhancing accuracy, efficiency, and adaptability in dynamic business environments.",
        icon: <GrOptimize />,
        button: "Maximize AI Potential"
    }
];


export default strategies