import { SiKubernetes } from "react-icons/si";
import { FaCloud } from "react-icons/fa";
import { GiPlatform } from "react-icons/gi";
import { GiOffshorePlatform } from "react-icons/gi";
import { GiServerRack } from "react-icons/gi";
import { GrDeploy } from "react-icons/gr";
import { FcDeployment } from "react-icons/fc";
import { MdOutlineSecurity } from "react-icons/md";
import { TbMapShare } from "react-icons/tb";
import { MdCloudSync } from "react-icons/md";


const k8s = [
    {
        "name": "Accelerate Software Delivery with Kubernetes-Powered Performance",
        "stat": "40%+",
        "unit": "Efficiency Gain",
        "desc": "Generative AI boosts productivity by up to 40% through automation and task optimization.",
        "icon": "<FaRocket />",
        "button": "Optimize Workflows",
        "source": "McKinsey"
    },
    {
        "name": "Optimize IT Costs with Kubernetes",
        "stat": "75%",
        "unit": "Leaders Using AI Insights",
        "desc": "By 2025, 75% of business leaders will leverage AI-driven insights for strategic decision-making.",
        "icon": "<FaChartLine />",
        "button": "Leverage Data Power",
        "source": "Gartner"
    },
    {
        "name": "The Self-Healing Heart of Your Container-Based Infrastructure",
        "stat": "$15.7T",
        "unit": "Global AI Contribution",
        "desc": "AI is projected to add $15.7 trillion to the global economy by 2030, reducing costs and creating new revenue streams.",
        "icon": "<FaMoneyBillWave />",
        "button": "Maximize Profits",
        "source": "PwC"
    },
    {
        "name": "Harness the Power of Multi-Cloud and Hybrid Cloud with Kubernetes",
        "stat": "52%",
        "unit": "Consumers Expect AI",
        "desc": "52% of consumers expect AI to enhance their shopping experience through personalization.",
        "icon": "<FaUserCheck />",
        "button": "Enhance Engagement",
        "source": "Salesforce"
    }
]



export default k8s