import React from "react"
import { FaTwitter } from "react-icons/fa";
import { CiYoutube } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa";

export const Footer = () => {

return (  <div className='h-[600px]  pl-4 pr-4'>
    {/*block*/}
    {/*
    <div className='col-span-1 text-white'>
      <h4 className='p-2 text-sm uppercase text-primary-300'>
        AI CONSULTING & SERVICES
      </h4>
      <div className='flex flex-col'>
        <a>AI Cloud</a>
        <a>Generative AI</a>
      </div>
    </div>*/}
    {/*Another block*/}
    <div className="h-[300px] flex flex-col  md:flex-row md:justify-center text-white w-[100%] md:h-[50%] md:items-center border-t  border-gray-700">
        <div className=" w-[100%] flex flex-col justify-center text-white w-[50%] h-[50%] items-center justify-end ">
            <p className="w-[100%]">
              <span className="text-2xl font-bold">RedaByte</span>
              <br/>
              <span className='font-mono text-xs text-gray-400 md:text-sm font-sans text-[14px] font-normal leading-[20px]'>Your Business' cloud accelerator</span>
              <br/>
              <span className='font-mono text-xs text-white md:text-sm font-sans text-[14px] font-normal leading-[20px]'><b>contact</b>: info@RedaByte.com</span> 
            </p>
            <p>
              
            </p>
        </div>
        <div className=" w-[100%] flex flex-col justify-center text-white w-[50%] h-[50%] md:items-center justify-end">
            <p>Join Our newsletter</p>
            <input></input>
            <span className='font-mono text-xs text-gray-400 md:text-sm font-sans text-[14px] font-normal leading-[20px]'>
            By subscribing you agree to with our Privacy Policy
            </span>
        </div>
    </div>
    <div className="pb-[10px] mb-[20px] md:h-[50%] pt-[30px] flex-col md:flex-row items-left flex md:justify-evenly border-t border-b border-gray-700">
    <div className='col-span-1 text-white md:mb-[10px]'>
      <h4 className='p-0 mb-2 text-m  font-sans font-normal leading-[20px]'>
        Professional Services
      </h4>
      <div className='flex flex-col justify-center text-gray-500 font-sans font-normal leading-[20px]'>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>Google Cloud Platform</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>Cloud Consulting</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>Cloud Native Development</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>DevOps/DevSecOps</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>Kubernetes Consulting</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>Observability</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>Platform Engineering</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>Site Reliability Engineering</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>Backstage Consulting</a>
      </div>
    </div>

    <div className='col-span-1 text-white'>
      <h4 className='p-0 mb-2 text-m  font-sans font-normal leading-[20px]'>
        Open source 
      </h4>
      <div className='flex flex-col justify-center text-gray-500 font-sans font-normal leading-[20px]'>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>PostgreSQL</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>Docker</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>ArgoCD</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>FluxCD</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>Grafana</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>Prometheus</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>Dapr</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>Backstage</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>OpenTelemetry</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>Apache Kafka</a>
      </div>
    </div>

    {/*Another block*/}
    
    {/*Another block*/}

    <div className='col-span-1 text-white'>
      <h4 className='p-0 mb-2 text-m  font-sans font-normal leading-[20px]'>
        Resources
      </h4>
      <div className='flex flex-col justify-center text-gray-500 font-sans font-normal leading-[20px]'>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>Blog</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>Customer Stories</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>Technical Insights</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>Glossary</a>
      </div>
    </div>

    <div className='col-span-1 text-white'>
      <h4 className='p-0 mb-2 text-m  font-sans font-normal leading-[20px]'>
        Company
      </h4>
      <div className='flex flex-col justify-center text-gray-500 font-sans font-normal leading-[20px]'>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>About Us</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>Careers</a>
      </div>
    </div>

    <div className='col-span-1 text-white'>
      <h4 className='p-0 mb-2 text-m  font-sans font-normal leading-[20px]'>
        Contact us
      </h4>
      <div className='flex flex-col justify-center text-gray-500 font-sans font-normal leading-[20px]'>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>cell: +265884107063</a>
        <a className='cursor-pointer hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>email: info@RedaByte.com</a>
      </div>
    </div>
  </div>
  <div className="mb-[20px] flex  flex-row justify-between items-center text-white w-[100%] md:h-[10%] mb-[50px]">
    <div className="w-[100%] md:w-[50%] font-sans font-normal leading-[20px]">
       <p>Copyright 2025</p> 
    </div>
    <div className="w-[100%] h-[80px]  flex justify-evenly items-center md:w-[50%]">
        <FaTwitter />
        <CiYoutube />
        <FaInstagram />
        <FaGithub />
    </div>
  </div>
  </div>
  )

}