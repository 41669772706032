import React, { useEffect, useState,useRef  } from 'react'
/*Generate Button*/
import services from '../data/services'
import { Navbar } from './navbar'
import { Footer } from './Footer'
import Marquee from 'react-fast-marquee'
import stats from '../data/stats'
import functions from '../data/functions'
import Industries from './industryUse'
import strategies from './Strategy'
import k8s from '../data/kubernetesReasons'

import { MainSlider } from './mainslider'
import { KubeSlider } from './kubeslider'

export  const Kubernetes = () => {

  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  // Track visibility of multiple sections/elements
  const sectionsRef = useRef([]);

  useEffect(() => {
    sectionsRef.current = sectionsRef.current.slice(0, 2); // Adjust based on sections

    // Remove animation class on page load to reset animations
    sectionsRef.current.forEach((section) => {
      if (section) {
        section.classList.remove("animate-appearslow");
        section.classList.add("opacity-0", "scale-50");
      }
    });

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-appearslow");
            entry.target.classList.remove("opacity-0", "scale-50");
            // observer.unobserve(entry.target); // Uncomment if you want it to animate only once
          }
        });
      },
      { threshold: 0.2 } // Adjust threshold as needed
    );

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);
    
 
  return (
    <>
    <div  className=" flex h-screen w-screen bg-[#18181b] flex-col">
          {/*Top Bar Studio area*/}
          <Navbar></Navbar>
          {/*Main content Area*/}
          {<div  className='flex-1 overflow-y-auto items-center justify-center'>
              {
                /*Hero section main header*/
              <div  className=' h-[200px] w-[100%] mt-[20px] md:mb-[50px] self-center items-center flex  text-white md:font-extrabold text-center md:text-left justify-center md:flex '>
                <p ref={(el) => (sectionsRef.current[0] = el)} className='w-[50%] text-center p-2 '> 
                  <br/>
                  <span className='font-sans text-2xl font-bold md:text-6xl text-white'>Kubernetes Solutions</span>
                  <br/>
                  <br/>
                  
                </p>
                
                
                
                
              {/* <h1>Trusted Cloud Native & AI Solutions Partner</h1>*/}
              </div>
              }
              {
                <div className='flex'>
                    <div style={{ backgroundImage: "url('6192206-removebg.png')", backgroundSize: "cover",
                        backgroundPosition: "center", width: "100vw"}} className='h-[400px] w-[50%] md:mt-[20px]  self-center  flex flex-col items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
                            <img className='xs:w-fit xs:h-fit md:w-[500px] md:h-[500px]' src="kubernetes.png" alt="" />
                            <p>Talk to an Expert!</p>
                            <button className='mt-[10px] mb-[30px] bg-[#18181b] w-[200px] h-[300px] border-gray-700 border rounded font-sans text-[14px] font-normal leading-[20px] cursor-pointer shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>
                            contact</button>
                            <span className='font-mono text-xs text-gray-400 md:text-sm font-sans text-[14px] font-normal leading-[20px]'>
                            
                            </span>
                            <span className='font-mono text-xs text-white md:text-sm font-sans text-[14px] font-normal leading-[20px]'>
                            Ready to Start your Kubernetes Journey? Get in touch with one of our experts
                            </span>
                    </div>
                    <div className='w-[50%] h-[400px] flex items-center justify-center'>
                        <KubeSlider></KubeSlider>
                    </div>
                </div>
              }
              {
                  /*Hero section main header*/
              
              <div  ref={(el) => (sectionsRef.current[1] = el)} className='animate-appearslow  mt-[200px] mb-[40px] self-center flex-col flex items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
                
                <p className='w-[100%] text-center p-2 '> 
                  <br/>
                </p>
                <div className=' text-xs md:text-2xl flex flex-col self-center font-mono  text-white md:font-extrabold  md:flex md:flex-col w-[80%]'>
                <h1 className='m-4 text-center font-sans  font-normal leading-[20px]'>Why us on Kubernetes?</h1>
                <div className='w-[100%] h-[40%] mt-[100px]'>
                  <div className='w-[100%]  md:text-m justify-evenly grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4'>
                    {
                      k8s.map((s)=><div className=' text-white hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105 flex flex-col justify-between items-center rounded-lg /[0.05]  p-4 shadow-md md:h-[350px]'>
                      <div className='flex justify-evenly '>
                        <h5 className='text-red-200 text-bold text-2xl md:text-2xl font-bold font-sans text-[14px] font-normal leading-[20px] text-center'>{s.name}</h5>
                      </div> 
                      <span className='text-2xl font-bold'>{s.stat} </span>
                      <span className='font-sans text-[14px] font-normal leading-[20px]'>{s.unit}</span>
                      <p className='font-sans text-[14px] font-normal leading-[20px] text-center'>{s.desc}</p>
                      
                    </div>)
                    }
                    
                  </div>
                </div>
                {/*<Slide></Slide>*/}
              </div>
            {/* <h1>Trusted Cloud Native & AI Solutions Partner</h1>*/}
            </div>
            }
              {
                  /*Hero section main header*/
              
              <div  ref={(el) => (sectionsRef.current[1] = el)} className='bg-[#303036] animate-appearslow  mt-[150px] mb-[40px] self-center flex-col flex items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
                
                <p className='w-[100%] text-center p-2 '> 
                  <span className="p-2 font-sans text-[14px] font-normal leading-[20px] text-2xl text-center text-md md:text-2xl ">
                      
                  </span>
                  <br/>
                </p>
                <div className='font-sans text-[14px] font-normal leading-[20px] text-2xl text-center text-md md:text-2xl text-xs md:text-2xl flex flex-col self-center font-mono  text-white md:font-extrabold  md:flex md:flex-col w-[80%]'>
                <h1 className='m-4 text-center'>Strategy Development and Guidance</h1>
                <div className='w-[100%] bg-[#303036] h-[600px] mt-[100px]'>
                  <div className='w-[100%] h-[50%] md:text-m flex justify-evenly '>
                    <div className='w-[50%] h-[100%]'>
                      <p className='font-sans text-[14px] font-normal leading-[20px]'>Elevate Your Kubernetes Journey with our Strategic Consulting

                        Cloud native approach for your application or services is usually driven by business reasons. Whether you're just beginning or have 
                        an established setup, 
                        we work with you to determine your requirements and offer practical suggestions for 
                        improvement and optimization based on your level of maturity.
                        </p> 
                    </div>
                    <div className='w-[50%] h-[100%] items-center justify-center flex'>
                      <img className='self-center w-[70%] h-[70%] bg-gray-100' src="strategy.svg" />
                    </div>
                  </div>
                  <div className='w-[100%] h-[50%] md:text-m flex justify-evenly '>
                    <div className='w-[50%] h-[100%] flex items-center justify-center'>
                      <img className='self-center w-[70%] h-[70%]' src="steps.svg" />
                    </div>
                    <div className='w-[50%] h-[100%]'>
                    <p className='font-sans text-[14px] font-normal leading-[20px]'>Elevate Your Kubernetes Journey with our Strategic Consulting

                        Cloud native approach for your application or services is usually driven by business reasons. Whether you're just beginning or have 
                        an established setup, 
                        we work with you to determine your requirements and offer practical suggestions for 
                        improvement and optimization based on your level of maturity.
                        </p> 
                    </div>
                  </div>
                </div>
                {/*<Slide></Slide>*/}
              </div>
            {/* <h1>Trusted Cloud Native & AI Solutions Partner</h1>*/}
            </div>
            }

              
            {
              <div className='h-[500px] w-[100%] mt-[20px] self-center  flex  items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
              <div className='h-[100%] w-[50%] flex items-center justify-center flex-col'>
                <p className='w-[100%] text-center p-2 flex flex-col items-center '>
                  <span className="p-2 font-sans text-[14px] font-normal leading-[20px] text-2xl text-center text-md md:text-2xl border-t border-b border-gray-700">
                      Deploy AI Powered Agents
                  </span>
                  <span className='font-mono text-xs text-gray-400 md:text-sm font-sans text-[14px] font-normal leading-[20px]'>
                    We help businesses of all sizes to adopt Artificial Intelligence solutions, to transform &  scale their business
                  </span>
                  <img className='w-[20%] h-[20%]' src="ai_gif.gif" alt="" />
                </p>
                <div>
                  <button className='w-[200px] h-[50px] border-gray-700 border rounded font-sans text-[14px] font-normal leading-[20px] cursor-pointer shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>
                    Discover More</button>
                </div>
              </div>
              {
                /*
                <div
                
                 className='h-[100%] w-[50%] flex items-center justify-center'
                >
                
              </div>
                
                */
              }
              
              
            {/* <h1>Trusted Cloud Native & AI Solutions Partner</h1>*/}
            </div>
            }
            {
                  /*Hero section main header*/
              
              <div  ref={(el) => (sectionsRef.current[1] = el)} className='bg-[#303036] animate-appearslow  mt-[150px] mb-[40px] self-center flex-col flex items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
                
                <p className='w-[100%] text-center p-2 '> 
                  <span className="p-2 font-sans text-[14px] font-normal leading-[20px] text-2xl text-center text-md md:text-2xl ">
                      
                  </span>
                  <br/>
                </p>
                <div className='font-sans text-[14px] font-normal leading-[20px] text-2xl text-center text-md md:text-2xl text-xs md:text-2xl flex flex-col self-center font-mono  text-white md:font-extrabold  md:flex md:flex-col w-[80%]'>
                <h1 className='m-4 text-center'>Cluster Design and Architecture</h1>
                <div className='w-[100%] bg-[#303036] h-[600px] mt-[100px]'>
                  <div className='w-[100%] h-[50%] md:text-m flex justify-evenly '>
                    <div className='w-[50%] h-[100%]'>
                      <p className='font-sans text-[14px] font-normal leading-[20px]'>Elevate Your Kubernetes Journey with our Strategic Consulting

                        Cloud native approach for your application or services is usually driven by business reasons. Whether you're just beginning or have 
                        an established setup, 
                        we work with you to determine your requirements and offer practical suggestions for 
                        improvement and optimization based on your level of maturity.
                        </p> 
                    </div>
                    <div className='w-[50%] h-[100%] items-center justify-center flex'>
                      <img className='self-center w-[70%] h-[70%] bg-gray-100' src="design.svg" />
                    </div>
                  </div>
                  <div className='w-[100%] h-[50%] md:text-m flex justify-evenly '>
                    <div className='w-[50%] h-[100%] flex items-center justify-center'>
                      <img className='self-center w-[70%] h-[70%]' src="kubernetes_platforms.jpg" />
                    </div>
                    <div className='w-[50%] h-[100%]'>
                    <p className='font-sans text-[14px] font-normal leading-[20px]'>Elevate Your Kubernetes Journey with our Strategic Consulting

                        Cloud native approach for your application or services is usually driven by business reasons. Whether you're just beginning or have 
                        an established setup, 
                        we work with you to determine your requirements and offer practical suggestions for 
                        improvement and optimization based on your level of maturity.
                        </p> 
                    </div>
                  </div>
                </div>
                {/*<Slide></Slide>*/}
              </div>
            {/* <h1>Trusted Cloud Native & AI Solutions Partner</h1>*/}
            </div>
            }
            
            {
                  /*Hero section main header*/
              <div ref={(el) => (sectionsRef.current[3] = el)} className='mt-[100px] mb-[40px] self-center flex-col flex items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
                <p className='w-[100%] text-center p-2 '> 
                  <span className="p-2 font-sans text-[14px] font-normal leading-[20px] text-2xl text-center text-md md:text-2xl ">
                      Industry Use Cases
                  </span>
                  <br/>
                </p>

                <div className='mt-[50px] text-xs md:text-2xl flex flex-col self-center font-mono  text-white md:font-extrabold  md:flex md:flex-col w-[80%]'>
                <div className='w-[100%] h-[40%]'>
                  <div className='w-[100%]  md:text-m justify-evenly grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4'>
                  {
                      Industries.map((s)=><div className=' bg-[#18181b] text-white hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105 flex flex-col justify-between items-center rounded-lg border border-gray-700 /[0.05]  p-4 shadow-md md:h-[500px]'>
                      <div className='flex justify-evenly bg-[#18181b]'>
                        <h5 className='font-sans text-[14px] font-normal leading-[20px] font-bold'>{s.name}</h5>
                      </div>
                      <div><div><img src={s.img} alt="" /></div></div>  
                      <p className='font-sans text-[14px] font-normal leading-[20px] text-center'>{s.desc}</p>
                      
                    </div>)
                    }
                    
                  </div>
                </div>
                {/*<Slide></Slide>*/}
              </div>
            {/* <h1>Trusted Cloud Native & AI Solutions Partner</h1>*/}
            </div>
            }
            {
                  /*Hero section main header*/
              <div ref={(el) => (sectionsRef.current[3] = el)} className='mt-[100px] mb-[40px] self-center flex-col flex items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
                <p className='w-[100%] text-center p-2 '> 
                  <span className="p-2 font-sans text-[14px] font-normal leading-[20px] text-2xl text-center text-md md:text-2xl ">
                      Our Strategy
                  </span>
                  <br/>
                </p>

                <div className='mt-[50px] text-xs md:text-2xl flex flex-col self-center font-mono  text-white md:font-extrabold  md:flex md:flex-col w-[80%]'>
                <div className='w-[100%] h-[40%]'>
                  <div className='w-[100%]  md:text-m justify-evenly grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4'>
                    {
                      strategies.map((s,index)=><div className='bg-white text-white transform transition-transform duration-200 hover:scale-105 flex flex-col justify-between items-center rounded-lg border border-gray-700/[0.05] bg-white/[.05] p-4 shadow-md md:h-[350px]'>
                      <div className='flex justify-evenly items-center w-[100%] h-[80%]'> 
                        <span className='hidden md:block'>{s.icon ? s.icon : null}</span>
                        <span>{s.name}</span>
                      </div>
                      <p className='font-sans text-2xl'>{index + 1}</p>
                    </div>
                    )
                    }
                    
                  </div>
                </div>
                {/*<Slide></Slide>*/}
              </div>
            {/* <h1>Trusted Cloud Native & AI Solutions Partner</h1>*/}
            </div>
            }
            {
              <div className='h-[300px] w-[100%] mt-[10px] self-center  flex flex-col items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
              <div className='h-[100%] w-[100%] flex flex-col items-center justify-center'>
              <p className='mb-[25px] w-[100%] text-center p-2 flex flex-col items-center '>
                  <span className="p-2 font-sans text-[14px] font-normal leading-[20px] text-2xl text-center text-md md:text-2xl border-t border-b border-gray-700">
                      Our Generative AI ecosystem
                  </span>
              </p>
              <Marquee className='mt-[40px]' speed={50} gradient={true} loop={0} gradientColor='#18181b'>
                  <div className="flex gap-16 px-2">
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/6643288f1f0d61c86298fe8c_Weaviate.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf12aecf44004f4a2f3b9_Nvidia.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf1302854cbc4f736bd38_HuggingFace.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf09d63a5ece245f384a2_AWS.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf0b6123081c24be2e1f8_Google_cloud.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/66bdb7961eef9ee3577558ea_Azure.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="qdrant2.png" alt="Image 1" className="" />
                    </div>

                    <div className='ml-[4px] flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/6643288f1f0d61c86298fe8c_Weaviate.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf12aecf44004f4a2f3b9_Nvidia.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf1302854cbc4f736bd38_HuggingFace.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf09d63a5ece245f384a2_AWS.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf0b6123081c24be2e1f8_Google_cloud.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/66bdb7961eef9ee3577558ea_Azure.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="qdrant2.png" alt="Image 1" className="" />
                    </div>


                    <div className='ml-[4px] flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/6643288f1f0d61c86298fe8c_Weaviate.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf12aecf44004f4a2f3b9_Nvidia.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf1302854cbc4f736bd38_HuggingFace.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf09d63a5ece245f384a2_AWS.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf0b6123081c24be2e1f8_Google_cloud.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/66bdb7961eef9ee3577558ea_Azure.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="qdrant2.png" alt="Image 1" className="" />
                    </div>



                    <div className='ml-[4px] flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/6643288f1f0d61c86298fe8c_Weaviate.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf12aecf44004f4a2f3b9_Nvidia.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf1302854cbc4f736bd38_HuggingFace.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf09d63a5ece245f384a2_AWS.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf0b6123081c24be2e1f8_Google_cloud.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/66bdb7961eef9ee3577558ea_Azure.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="qdrant2.png" alt="Image 1" className="" />
                    </div>





                    <div className='ml-[4px] flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/6643288f1f0d61c86298fe8c_Weaviate.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf12aecf44004f4a2f3b9_Nvidia.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf1302854cbc4f736bd38_HuggingFace.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf09d63a5ece245f384a2_AWS.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf0b6123081c24be2e1f8_Google_cloud.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/66bdb7961eef9ee3577558ea_Azure.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="qdrant2.png" alt="Image 1" className="" />
                    </div>
                  </div>
                </Marquee>
              </div>
            </div>
            }

            {
              <div className='h-[500px] w-[100%] mt-[100px] self-center  flex  items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
              <div className='h-[100%] w-[50%] flex flex-col items-center justify-center'>
                <p className='w-[100%] text-center p-2 flex flex-col items-center '>
                  <span className="p-2 font-sans text-[14px] font-normal leading-[20px] text-2xl text-center text-md md:text-2xl border-t border-b border-gray-700">
                      Get in Touch with Us Today!
                  </span>
                </p>
                <div style={{ backgroundImage: "url('6192206-removebg.png')", backgroundSize: "cover",
                backgroundPosition: "center", width: "100vw"}} className='h-[90%] w-[50%] mt-[100px]  self-center  flex flex-col items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
                    <p>Talk to an Expert!</p>
                    <button className='mt-[10px] mb-[30px] bg-[#18181b] w-[200px] h-[50px] border-gray-700 border rounded font-sans text-[14px] font-normal leading-[20px] cursor-pointer shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>
                    contact</button>
                    <span className='font-mono text-xs text-gray-400 md:text-sm font-sans text-[14px] font-normal leading-[20px]'>
                    
                    </span>
                    <span className='font-mono text-xs text-white md:text-sm font-sans text-[14px] font-normal leading-[20px]'>
                    Ready to transform your business and have  real impact with AI? Get in touch with one of our experts
                    </span>
                </div>
              </div>
            </div>
            }
            {
              <Footer></Footer>

            }

          </div>
          }
          
    </div>
      
  
    </>)
        
} 