import React from 'react'
import { Navbar } from '../components/navbar'
import { Slide } from '../components/slider'
import services from '../data/services'
import { Opensource } from '../components/opensource'
import insights from '../data/insights'
import YourComponent from '../components/FadingText'
import { MainSlider } from '../components/mainslider'
import Carousel from '../components/custom-carousel'
import { Partners } from '../components/partners'
import Marquee from 'react-fast-marquee'
import { useEffect,useRef } from 'react'
import { Footer } from '../components/Footer'

/*#030527*/
//030527 0C213E #EFF6FF
export const Home = () => {
  // Track visibility of multiple sections/elements
    const sectionsRef = useRef([]);

    useEffect(() => {
      sectionsRef.current = sectionsRef.current.slice(0, 2); // Adjust based on sections

      // Remove animation class on page load to reset animations
      sectionsRef.current.forEach((section) => {
        if (section) {
          section.classList.remove("animate-appearslow");
          section.classList.add("opacity-0", "scale-50");
        }
      });

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("animate-appearslow");
              entry.target.classList.remove("opacity-0", "scale-50");
              // observer.unobserve(entry.target); // Uncomment if you want it to animate only once
            }
          });
        },
        { threshold: 0.2 } // Adjust threshold as needed
      );

      sectionsRef.current.forEach((section) => {
        if (section) observer.observe(section);
      });

      return () => {
        sectionsRef.current.forEach((section) => {
          if (section) observer.unobserve(section);
        });
      };
    }, []);

  return (
    <>
    <div className='bg-[#18181b] w-[100%] h-[100%] flex flex-col'>
        <Navbar/>
        {<div  className='flex-1 overflow-y-auto items-center justify-center'>
        <MainSlider></MainSlider>
        {/*<YourComponent/>*/}
        {<div className='md:pt-[50px]  self-center md:h-[80%] flex items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
            <p className='w-[100%] mt-10 p-2 text-center'> 
              <span className='text-2xl md:font-bold text-center text-md md:text-2xl'>Scale and Streamline your business by leveraging</span> 
              <br/>
              <span className='text-2xl mdfont-bold md:text-6xl text-white'>Cloud Native Solutions </span>
              <br/>
              <br/>
              <span className='font-sans text-[14px] font-normal leading-[20px] font-mono text-xs text-gray-400 md:text-sm'>
                Our expertise is to help businesses of all sizes to adopt Cloud native solutions, to transform &  scale their business
              </span>
            </p>
           {/* <h1>Trusted Cloud Native & AI Solutions Partner</h1>*/}
        </div>
        }
        {
          <div>
            <div className='font-sans text-[14px] font-normal leading-[20px] text-xs md:text-2xl flex flex-col font-mono items-center text-white md:font-extrabold text-center justify-center md:flex md:flex-col h-[30%] w-[100%]'>
            <h1>Trusted by reputable and heavyweight Organizations</h1>
            {/*<Slide></Slide>*/}
          </div>
          <div className='m-10 md:m-[100px]'>
            {/*<Slide></Slide>*/}
            {
                <Marquee speed={50} gradient={true}  gradientColor='#18181b'>
                  <div className="flex gap-8">
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="Paladium_logo-removebg.png" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="apalis.png" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/6643288f1f0d61c86298fe8c_Weaviate.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf12aecf44004f4a2f3b9_Nvidia.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf1302854cbc4f736bd38_HuggingFace.svg" alt="Image 1" className="" />
                    </div>



                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="Paladium_logo-removebg.png" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="apalis.png" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/6643288f1f0d61c86298fe8c_Weaviate.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf12aecf44004f4a2f3b9_Nvidia.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf1302854cbc4f736bd38_HuggingFace.svg" alt="Image 1" className="" />
                    </div>


                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="Paladium_logo-removebg.png" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="apalis.png" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/6643288f1f0d61c86298fe8c_Weaviate.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf12aecf44004f4a2f3b9_Nvidia.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf1302854cbc4f736bd38_HuggingFace.svg" alt="Image 1" className="" />
                    </div>


                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="Paladium_logo-removebg.png" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="apalis.png" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/6643288f1f0d61c86298fe8c_Weaviate.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf12aecf44004f4a2f3b9_Nvidia.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf1302854cbc4f736bd38_HuggingFace.svg" alt="Image 1" className="" />
                    </div>


                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="Paladium_logo-removebg.png" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="apalis.png" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/6643288f1f0d61c86298fe8c_Weaviate.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf12aecf44004f4a2f3b9_Nvidia.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf1302854cbc4f736bd38_HuggingFace.svg" alt="Image 1" className="" />
                    </div>



                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="Paladium_logo-removebg.png" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="apalis.png" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/6643288f1f0d61c86298fe8c_Weaviate.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf12aecf44004f4a2f3b9_Nvidia.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf1302854cbc4f736bd38_HuggingFace.svg" alt="Image 1" className="" />
                    </div>


                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="Paladium_logo-removebg.png" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="apalis.png" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/6643288f1f0d61c86298fe8c_Weaviate.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf12aecf44004f4a2f3b9_Nvidia.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf1302854cbc4f736bd38_HuggingFace.svg" alt="Image 1" className="" />
                    </div>
                  </div>
                  
                </Marquee>
              }
          </div>
          
        </div>
        }
        {
                  /*Hero section main header*/
              
              <div  ref={(el) => (sectionsRef.current[0] = el)} className='animate-appearslow mt-[100px]  md:mt-[300px] mb-[40px] self-center flex-col flex items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
                
                <p className='w-[100%] text-center p-2 '> 
                  <span className="p-2 font-sans text-[14px] font-normal leading-[20px] text-2xl text-center text-md md:text-2xl ">
                      Our Value to You
                  </span>
                  <br/>
                </p>
                <p className='pt-5 flex flex-col items-center justify-center text-center'>
                  <span className='text-center mt-4 font-sans font-normal leading-[20px]  font-bold md:text-4xl text-white'>Cloud Native Enterprise Solutions</span>
                    <br/>
                    <br/>
                    <span className='font-mono  text-gray-400 md:text-sm font-sans text-[14px] font-normal leading-[20px]'>
                      We help businesses of all sizes to adopt cloud native solutions, to transform &  scale their business
                  </span>
                </p>

                <div className='font-sans text-[14px] font-normal leading-[20px] text-xs md:text-2xl flex flex-col self-center font-mono  text-white md:font-extrabold  md:flex md:flex-col w-[80%]'>
                <h1 className='m-4'>Our Services</h1>
                <div className='w-[100%] h-[40%]'>
                  <div className='w-[100%]  md:text-m justify-evenly grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4'>
                    {
                      services.map((s)=><div className='bg-precision text-white hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105 flex flex-col justify-between items-center rounded-lg border border-gray-700 /[0.05] bg-white/[.05] p-4 shadow-md md:h-[350px]'>
                      <div className='flex justify-evenly'>
                        {s.icon?s.icon:null}
                        <h5 className='font-sans text-[14px] font-normal leading-[20px]'>{s.name}</h5>
                      </div>  
                      <p className='font-sans text-[14px] font-normal leading-[20px]'>{s.desc}</p>
                      <button className='text-white bg-precision h-[50px] w-[60%] font-sans text-[14px] font-normal leading-[20px]'>{s.button}</button>
                    </div>)
                    }
                    
                  </div>
                </div>
                {/*<Slide></Slide>*/}
              </div>
            {/* <h1>Trusted Cloud Native & AI Solutions Partner</h1>*/}
            </div>
            }
            {
              <div className='h-[500px] w-[100%] mt-[20px] self-center  flex  items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
              <div className='h-[100%] w-[50%] flex items-center justify-center flex-col'>
                <p className='w-[100%] text-center p-2 flex flex-col items-center '>
                  <span className="p-2 font-sans text-[14px] font-normal leading-[20px] text-2xl text-center text-md md:text-2xl border-t border-b border-gray-700">
                  Build an AI Platform with Kubernetes
                  </span>
                  <span className=' mt-[5px] font-mono text-xs text-gray-400 md:text-sm font-sans text-[14px] font-normal leading-[20px]'>
                    We help businesses of all sizes to adopt Cloud-native & Artificial Intelligence solutions, to transform &  scale their business
                  </span>
                  <img className='w-[20%] h-[60%]' src="kubernetes.png" alt="" />
                </p>
                <div>
                  <button className='w-[200px] h-[50px] border-gray-700 border rounded font-sans text-[14px] font-normal leading-[20px] cursor-pointer shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>
                    Discover More</button>
                </div>
              </div>
            </div>
            }
            {
                  /*Hero section main header*/
              <div ref={(el) => (sectionsRef.current[1] = el)} className='mt-[100px] mb-[40px] self-center flex-col flex items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
                <p className='w-[100%] text-center p-2 '> 
                  <span className="p-2 font-sans text-[14px] font-normal leading-[20px] text-2xl text-center text-md md:text-2xl border-b border-white">
                      Our Work
                  </span>
                  <br/>
                </p>
                <p className='pt-5'>
                  <span className=' mt-4 font-sans font-normal leading-[20px] font-bold md:text-4xl text-white'>Testimonials for our Enterprise Solutions</span>
                    <br/>
                    <br/>
                    <span className='font-mono  text-gray-400 md:text-sm font-sans text-[14px] font-normal leading-[20px]'>
                      We help businesses of all sizes to adopt Artificial Intelligence solutions, to transform &  scale their business
                  </span>
                </p>

                <div className=' text-xs md:text-2xl flex flex-col self-center font-mono  text-white md:font-extrabold  md:flex md:flex-col w-[80%]'>
                <h1 className='m-4'>Our Services</h1>
                <div className='w-[100%] h-[40%]'>
                  <div className='w-[100%]  md:text-m justify-evenly grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4'>
                    {
                      services.map((s)=><div className='bg-precision text-white hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105 flex flex-col justify-between items-center rounded-lg border border-gray-700 /[0.05] bg-white/[.05] p-4 shadow-md md:h-[350px]'>
                      <div className='flex justify-evenly'>
                        {s.icon?s.icon:null}
                        <h5 className='font-sans text-[14px] font-normal leading-[20px]'>{s.name}</h5>
                      </div>  
                      <p className='font-sans text-[14px] font-normal leading-[20px]'>{s.desc}</p>
                      <button className='text-white bg-precision h-[50px] w-[60%] font-sans text-[14px] font-normal leading-[20px]'>{s.button}</button>
                    </div>)
                    }
                    
                  </div>
                </div>
                {/*<Slide></Slide>*/}
              </div>
            {/* <h1>Trusted Cloud Native & AI Solutions Partner</h1>*/}
            </div>
            }

            {
              <div className='h-[300px] w-[100%] mt-[10px] self-center  flex flex-col items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
              <div className='h-[100%] w-[100%] flex flex-col items-center justify-center'>
              <p className='mb-[25px] w-[100%] text-center p-2 flex flex-col items-center '>
                  <span className="p-2 font-sans text-[14px] font-normal leading-[20px] text-2xl text-center text-md md:text-2xl border-t border-b border-gray-700">
                      our cloud-native ecosystem
                  </span>
              </p>
              <Marquee className='mt-[40px]' speed={50} gradient={true} loop={0} gradientColor='#18181b'>
                  <div className="flex gap-16 px-2">
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/6643288f1f0d61c86298fe8c_Weaviate.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf12aecf44004f4a2f3b9_Nvidia.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf1302854cbc4f736bd38_HuggingFace.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf09d63a5ece245f384a2_AWS.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf0b6123081c24be2e1f8_Google_cloud.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/66bdb7961eef9ee3577558ea_Azure.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="qdrant2.png" alt="Image 1" className="" />
                    </div>

                    <div className='ml-[4px] flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/6643288f1f0d61c86298fe8c_Weaviate.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf12aecf44004f4a2f3b9_Nvidia.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf1302854cbc4f736bd38_HuggingFace.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf09d63a5ece245f384a2_AWS.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf0b6123081c24be2e1f8_Google_cloud.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/66bdb7961eef9ee3577558ea_Azure.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="qdrant2.png" alt="Image 1" className="" />
                    </div>


                    <div className='ml-[4px] flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/6643288f1f0d61c86298fe8c_Weaviate.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf12aecf44004f4a2f3b9_Nvidia.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf1302854cbc4f736bd38_HuggingFace.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf09d63a5ece245f384a2_AWS.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf0b6123081c24be2e1f8_Google_cloud.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/66bdb7961eef9ee3577558ea_Azure.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="qdrant2.png" alt="Image 1" className="" />
                    </div>



                    <div className='ml-[4px] flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/6643288f1f0d61c86298fe8c_Weaviate.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf12aecf44004f4a2f3b9_Nvidia.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf1302854cbc4f736bd38_HuggingFace.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf09d63a5ece245f384a2_AWS.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf0b6123081c24be2e1f8_Google_cloud.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/66bdb7961eef9ee3577558ea_Azure.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="qdrant2.png" alt="Image 1" className="" />
                    </div>





                    <div className='ml-[4px] flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/6643288f1f0d61c86298fe8c_Weaviate.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf12aecf44004f4a2f3b9_Nvidia.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf1302854cbc4f736bd38_HuggingFace.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf09d63a5ece245f384a2_AWS.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf0b6123081c24be2e1f8_Google_cloud.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/66bdb7961eef9ee3577558ea_Azure.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="qdrant2.png" alt="Image 1" className="" />
                    </div>
                  </div>
                </Marquee>
              </div>
            </div>
            }
              {/*TechinCAL INSIGHTS*/}
              {
                  /*Technical Insights*/
              <div ref={(el) => (sectionsRef.current[2] = el)} className='mt-[100px] mb-[40px] self-center flex-col flex items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
                <p className='w-[100%] text-center p-2 '> 
                  <span className="p-2 font-sans text-[14px] font-normal leading-[20px] text-2xl text-center text-md md:text-2xl border-b border-white">
                      Technical Insights
                  </span>
                  <br/>
                </p>
                <p className='pt-5'>
                  <span className=' mt-4 font-sans font-normal leading-[20px]  font-bold md:text-4xl text-white'>Read our Blog Posts on Research and More to Learn</span>
                    <br/>
                    <br/>
                    <span className='text-center font-mono  text-gray-400 md:text-sm font-sans text-[14px] font-normal leading-[20px]'>
                      Read our Posts to find out more about out technical Expertise
                    </span>
                </p>

                <div className=' text-xs md:text-2xl flex flex-col self-center font-mono  text-white md:font-extrabold  md:flex md:flex-col w-[80%]'>
                <h1 className='m-4'></h1>
                <div className='w-[100%] h-[40%]'>
                  <div className='w-[100%]  md:text-m justify-evenly grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4'>
                    {
                      insights.map((s)=><div
                      className='font-sans font-normal leading-[20px] rounded-lg border border-white/[0.05] bg-white/[.05] p-4 shadow-md md:h-[350px]
                       transform transition-transform duration-200 hover:scale-105 hover:cursor-pointer hover:shadow-primary-300
                       hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)]'
                    >
                      <div
                        style={{ backgroundImage: `url(${s.image})` }}
                        className='w-[100%] h-[100px] bg-cover bg-no-repeat'
                      ></div>
                      <h6 className='text-sm'>{s.name}</h6>
                      <div className='flex items-center w-[50%] justify-between'>
                        <img className='w-6 rounded-full' src={s.author_image} alt={s.author}></img>
                        <span className='text-sm'>{s.author}</span>
                      </div>
                      <span className='text-sm'>{s.date}</span>
                      <p className='text-sm'>{s.desc}</p>
                    </div>)
                    }
                    
                  </div>
                </div>
                {/*<Slide></Slide>*/}
              </div>
            {/* <h1>Trusted Cloud Native & AI Solutions Partner</h1>*/}
            </div>
        }

{
              <div className='h-[500px] w-[100%] mt-[20px] self-center  flex  items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
              <div className='h-[100%] w-[50%] flex items-center justify-center flex-col'>
                <p className='w-[100%] text-center p-2 flex flex-col items-center '>
                  <span className="p-2 font-sans text-[14px] font-normal leading-[20px] text-2xl text-center text-md md:text-2xl border-t border-b border-gray-700">
                  Start your cloud native Journey on Google Cloud Platform with us!
                  </span>
                  <span className=' mt-[5px] font-mono text-xs text-gray-400 md:text-sm font-sans text-[14px] font-normal leading-[20px]'>
                    We help businesses of all sizes to adopt Cloud-native & Artificial Intelligence solutions, to transform &  scale their business
                  </span>
                  <img className='w-[20%] h-[60%]' src="Google_Cloud_Platform-Logo.wine.png" alt="" />
                </p>
                <div>
                  <button className='w-[200px] h-[50px] border-gray-700 border rounded font-sans text-[14px] font-normal leading-[20px] cursor-pointer shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>
                    Discover More</button>
                </div>
              </div>
            </div>
            }
        
        {
              <div className='h-[300px] w-[100%] mt-[10px] self-center  flex flex-col items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
              <div className='h-[100%] w-[100%] flex flex-col items-center justify-center'>
              <p className='mb-[25px] w-[100%] text-center p-2 flex flex-col items-center '>
                  <span className="p-2 font-sans text-[14px] font-normal leading-[20px] text-2xl text-center text-md md:text-2xl border-t border-b border-gray-700">
                      Our Technology Partners
                  </span>
              </p>
              <Marquee className='mt-[40px]' speed={50} gradient={true} loop={0} gradientColor='#18181b'>
                  <div className="flex gap-16 px-2">
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="isovalent-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="rancher-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="cloudflare-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="sap-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf0b6123081c24be2e1f8_Google_cloud.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/66bdb7961eef9ee3577558ea_Azure.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf09d63a5ece245f384a2_AWS.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="hashicorp-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="nvidia-seeklogo.svg" alt="Image 1" className="" />
                    </div>

                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="isovalent-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="rancher-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="cloudflare-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="sap-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf0b6123081c24be2e1f8_Google_cloud.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/66bdb7961eef9ee3577558ea_Azure.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf09d63a5ece245f384a2_AWS.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="hashicorp-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="nvidia-seeklogo.svg" alt="Image 1" className="" />
                    </div>


                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="isovalent-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="rancher-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="cloudflare-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="sap-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf0b6123081c24be2e1f8_Google_cloud.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/66bdb7961eef9ee3577558ea_Azure.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf09d63a5ece245f384a2_AWS.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="hashicorp-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="nvidia-seeklogo.svg" alt="Image 1" className="" />
                    </div>



                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="isovalent-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="rancher-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="cloudflare-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="sap-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf0b6123081c24be2e1f8_Google_cloud.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/66bdb7961eef9ee3577558ea_Azure.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf09d63a5ece245f384a2_AWS.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="hashicorp-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="nvidia-seeklogo.svg" alt="Image 1" className="" />
                    </div>





                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="isovalent-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="rancher-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="cloudflare-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="sap-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf0b6123081c24be2e1f8_Google_cloud.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/66bdb7961eef9ee3577558ea_Azure.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="https://cdn.prod.website-files.com/63d0e82ae86a206936c2c2fc/65faf09d63a5ece245f384a2_AWS.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="hashicorp-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                    <div className='flex items-center justify-center border-solid  border border-gray-700  rounded h-[78.35px] w-[175.5px] p-4'>
                      <img src="nvidia-seeklogo.svg" alt="Image 1" className="" />
                    </div>
                  </div>
                </Marquee>
              </div>
            </div>
            }

      {
                  /*Technical Insights*/
              <div ref={(el) => (sectionsRef.current[2] = el)} className='mt-[100px] mb-[40px] self-center flex-col flex items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
                <p className='w-[100%] text-center p-2 '> 
                  <span className="p-2 font-sans text-[14px] font-normal leading-[20px] text-2xl text-center text-md md:text-2xl border-b border-white">
                      Our Success Stories
                  </span>
                  <br/>
                </p>
                <p className='pt-5'>
                  <span className=' mt-4 font-sans font-normal leading-[20px]  font-bold md:text-4xl text-white'>Read our success for various clients</span>
                    <br/>
                    <br/>
                    <span className='text-center font-mono  text-gray-400 md:text-sm font-sans text-[14px] font-normal leading-[20px]'>
                    </span>
                </p>

                <div className=' text-xs md:text-2xl flex flex-col self-center font-mono  text-white md:font-extrabold  md:flex md:flex-col w-[80%]'>
                <h1 className='m-4'></h1>
                <div className='w-[100%] h-[40%]'>
                  <div className='w-[100%]  md:text-m justify-evenly grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4'>
                    {
                      insights.map((s)=><div
                      className='font-sans font-normal leading-[20px] rounded-lg border border-white/[0.05] bg-white/[.05] p-4 shadow-md md:h-[350px]
                       transform transition-transform duration-200 hover:scale-105 hover:cursor-pointer hover:shadow-primary-300
                       hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)]'
                    >
                      <div
                        style={{ backgroundImage: `url(${s.image})` }}
                        className='w-[100%] h-[100px] bg-cover bg-no-repeat'
                      ></div>
                      <h6 className='text-sm'>{s.name}</h6>
                      <div className='flex items-center w-[50%] justify-between font-sans font-normal leading-[20px]'>
                        <img className='w-6 rounded-full' src={s.author_image} alt={s.author}></img>
                        <span className='text-sm'>{s.author}</span>
                      </div>
                      <span className='text-sm'>{s.date}</span>
                      <p className='text-sm font-sans font-normal leading-[20px]'>{s.desc}</p>
                    </div>)
                    }
                    
                  </div>
                </div>
                {/*<Slide></Slide>*/}
              </div>
            {/* <h1>Trusted Cloud Native & AI Solutions Partner</h1>*/}
            </div>
        }

        {/*<div className='self-center mt-20 mb-20 text-xs md:text-2xl flex flex-col self-center font-mono  text-white md:font-extrabold  md:flex md:flex-col h-[70%] w-[80%]'>
          <h1>Customer Feedback</h1>
          <div className='w-[100%] h-[40%]'>
            <div className='text-sm rounded-lg border border-white/[0.05] bg-white/[.05] p-4 shadow-md'>
              <p>
              RedaByte assisted Rezolve.ai in migrating to the latest versions of Kubernetes and Postgres, while also taking advantage of additional capabilities. The task of managing live infrastructure that is actively used by customers in production requires special attention, and they successfully navigated this challenge. Furthermore, they researched new technologies relevant to our use case and provided valuable recommendations. Nitrovirt team was dedicated and professional in delivering the desired outcome, and we highly recommend their services.
              </p>
              
            </div>
          </div>
          
        </div>*/}
        {
              <div className='h-[500px] w-[100%] mt-[100px] self-center  flex  items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
              <div className='h-[100%] w-[50%] flex flex-col items-center justify-center'>
                <p className='w-[100%] text-center p-2 flex flex-col items-center '>
                  <span className="p-2 font-sans text-[14px] font-normal leading-[20px] text-2xl text-center text-md md:text-2xl border-t border-b border-gray-700">
                      Get in Touch with Us Today!
                  </span>
                </p>
                <div style={{ backgroundImage: "url('6192206-removebg.png')", backgroundSize: "cover",
                backgroundPosition: "center", width: "100vw"}} className='h-[90%] w-[50%] mt-[10px]  self-center  flex flex-col items-center text-white md:font-extrabold text-center md:text-left justify-center md:flex mb-10'>
                    <p>Talk to an Expert!</p>
                    <button className='mt-[10px] mb-[30px] bg-[#18181b] w-[200px] h-[50px] border-gray-700 border rounded font-sans text-[14px] font-normal leading-[20px] cursor-pointer shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105'>
                    contact</button>
                    <span className='font-mono text-xs text-gray-400 md:text-sm font-sans text-[14px] font-normal leading-[20px]'>
                    
                    </span>
                    <span className='font-mono text-xs text-white md:text-sm font-sans text-[14px] font-normal leading-[20px]'>
                    Ready to transform your business and have  real impact with cloud-native solutions? Get in touch with one of our experts
                    </span>
                </div>
              </div>
            </div>
            }

            { 
              <Footer></Footer>

            }
        </div>}
    </div>
    </>)
}


