import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
/*Generate Button*/

import { TiThMenu } from "react-icons/ti";
import menus from '../data/Menu';


export const Navbar = () => {

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [openDropdown, setOpenDropdown] = useState(null);

    const [openMenu, setOpenMenu] = useState(null);

    // Function to toggle submenu
    const toggleMenu = (menu) => {
      setOpenMenu(openMenu === menu ? null : menu);
    };
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setOpenDropdown(null);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  return (
    <>
          {/* Top Bar Studio area */
            <div className='hidden md:flex border-b border-gray-700 bg-opacity-100 text-white p-4 h-[8%] w-[100%] font-sans text-[14px] font-normal leading-[20px] text-gray-400 p-2 shadow-2xl items-center justify-center'>
      
            <div className='w-[30%] h-[100%] pl-4 flex items-center hover:cursor-pointer'>
              <span className='text-2xl bold flex items-center justify-center'>
                <img src='vectors-logo.png' className='w-[30px] h-[30px] pr-2' alt="" />
                <b>RedaByte</b>
              </span>                  
            </div>
      
            <div className='text-white w-[40%] h-[100%] flex justify-between items-center' ref={dropdownRef}>
      
              {menus.map((item, index) => (
                item.show?
                <div 
                  key={index}
                  className="relative dropdown"
                  onMouseEnter={() => setOpenDropdown(item.name)}
                >
                  <button className="flex items-center justify-center">
                    <Link to={!item.dropdown?`${item.link}`:``} >{item.name}</Link>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                  {openDropdown === item.name && item.dropdown && (
                    <div className=" bg-[#18181b] absolute left-0 mt-2 h-[300px] w-[200px] bg-gray-800 border border-gray-700 text-white rounded-md shadow-lg">
                      <ul className="p-2 bg-[#18181b] h-[100%]">
                        {item.options.map((option, i) => (
                          <li className="p-2 hover:bg-gray-700 cursor-pointer">
                            
                            <Link className='w-[100%]' to={option.link} key={i} >
                          {option.button}
                          </Link>
                        </li>
                          
                        ))}
                      </ul>
                    </div>
                  )}
                </div>:null
              ))}
      
            </div>
      
            <div className='text-white w-[30%] h-[100%] flex justify-end items-center'>
              <div className='pl-40 flex justify-evenly w-[100%] h-[100%]'>
                {/* Additional links/buttons can be added here */}
              </div>
            </div>                           
          </div>
        
      }
          {/* Top Bar Mobile */
        <div className='md:hidden border-b border-gray-700 bg-opacity-100 text-white p-4 flex h-[7%] w-[100%] font-sans text-[14px] font-normal leading-[20px] text-gray-400  p-2 shadow-2xl items-center justify-center'>
            <div className='w-[30%] h-[100%] pl-4 flex items-center hover:cursor-pointer'>
              {/*<img src='3dlogo.png' className='h-[70%] w-[10%]'></img>*/}
              <span className='bold flex items-center justitfy-center'>
                <img src='vectors-logo.png' className='w-[20px] h-[20px] pr-2'></img>
                <b>Vectors4D</b>
                </span>                  
            </div>
            <div className='text-white w-[40%] h-[100%] flex justify-between items-center'>
                
            </div>
            <div className='text-white w-[30%] h-[100%] flex justify-end items-center '>
                <TiThMenu onClick={()=>setIsOpen(!isOpen)} className='cursor-pointer w-[20px] h-[20px]'/>
            </div>                           
        </div>
        
      }
      {/* Nav Menu Mobile */
        <div
        className={`md:hidden fixed z-50 top-0 w-[70%] h-screen bg-[#18181b] flex flex-col shadow-2xl transition-transform duration-500 ease-in-out transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="text-white w-full h-full flex flex-col items-center mt-12">
          {/* Logo */}
          <img src="vectors-logo.png" className="w-[30px] h-[30px] pr-2" />
  
          {/* Menu Items */}
          {menus.map((item, index) => (
            <div key={index} className="w-full">
              <button
                onClick={() => toggleMenu(item.name)}
                className="font-sans text-[14px] font-normal leading-[20px] text-gray-400 p-[15px] flex items-center justify-center border-b border-gray-700 w-[100%] hover:text-white"
              >
                <span className="font-bold">{item.name}</span>
              </button>
  
              {/* Submenu */}
              <div
                className={`overflow-hidden transition-all duration-300 ${
                  openMenu === item.name ? "max-h-40 opacity-100" : "max-h-0 opacity-0"
                }`}
              >
                <div className="flex flex-col bg-[#252529] text-gray-300">
                {item.options.map((option, i) => (
                          <li className="font-sans text-[14px] font-normal leading-[20px] text-gray-400 p-[15px] flex items-center justify-center border-b border-gray-700 w-[100%] hover:text-white">
                            
                            <Link className='w-[100%]' to={option.link} key={i} >
                          {option.button}
                          </Link>
                        </li>
                          
                        ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    
  }
  
    </>)
        
}